import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo-min.png';
import styled from 'styled-components';
import theme from './theme';

const Nav = styled.nav`
  background-color: ${theme.colors.primary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const LinkImg = styled(Link)`
  &&& {
    transition: all 0.3s ease;
    background-color: #fff;
    :hover {
      background-color: #fff;
    }
    :active {
      background-color: #fff;
    }
    :focus {
      background-color: #fff;
    }
    min-width: 3.25rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

const NAV_HEIGHT = 52;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${(p) => (p.active ? 25 : -1)};
  opacity: ${(p) => (p.active ? 1 : 0)};
  pointer-events: ${(p) => (p.active ? 'all' : 'none')};
  transition: all 0.3s ease;
`;

const LinkText = styled(Link)`
  color:  ${(p) => (p.active ? '#FFF' : theme.colors.primary)}!important;
  transition: all 0.3s ease;
  background-color: ${(p) => (p.active ? theme.colors.primary : 'transparent')}!important;
  padding: 2rem 0 !important;
  @media screen and (min-width: 1023px) {
    &&& {
      background-color: ${(p) => (p.active ? '#FFF' : 'transparent')}!important;

      color:  ${(p) => (p.active ? '#222' : '#FFF')}!important;
      padding: 0 2rem !important;
      :hover {
        background-color: #fff!important;
        color: #222 !important;
      }
      :active {
        background-color: #fff!important;
        color: #222 !important;
      }
      :focus {
        background-color: #fff!important;
        color: #222 !important;
      }
    }
  }
`;

const els = ['intro', 'offer', 'translations', 'contact'];

const Navbar = class extends React.Component {
  componentDidMount() {
    this.getRefs();
    window.addEventListener('scroll', this.handleScroll);
  }

  refs = {};

  getRefs = () => {
    const contact = document.getElementById('contact');
    const translations = document.getElementById('translations');
    const offer = document.getElementById('offer');
    const intro = document.getElementById('intro');
    this.refs = {
      contact,
      translations,
      offer,
      intro,
    };
  };

  handleScroll = () => {
    const activeNav = els.find((name) => {
      const el = this.refs[name];
      if (!el) {
        return;
      }
      const box = el.getBoundingClientRect();
      console.log(name, box);
      return box.top + box.height - NAV_HEIGHT >= 0;
    });
    if (this.state.activeNav !== activeNav) {
      this.setState({ activeNav });
    }
  };

  state = {
    activeNav: els[0],
    active: false,
    navBarActiveClass: '',
  };

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  closeHamburger = () => {
    if (!this.state.active) {
      return;
    }
    this.toggleHamburger();
  };

  render() {
    const { activeNav } = this.state;
    return (
      <>
        <Nav
          className="navbar is-fixed-top has-text-white-ter"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand ">
              <LinkImg to="#intro" className="navbar-item" title="Logo">
                <img src={logo} alt="Avus" style={{ width: '22px' }} />
              </LinkImg>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger has-text-white-ter burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={this.toggleHamburger}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-start has-text-centered">
                <LinkText
                  active={activeNav === 'offer'}
                  onClick={this.closeHamburger}
                  className="navbar-item has-text-white-ter"
                  to="#offer"
                >
                  Oferta
                </LinkText>
                <LinkText
                  active={activeNav === 'translations'}
                  onClick={this.closeHamburger}
                  className="navbar-item has-text-white-ter"
                  to="#translations"
                >
                  Zakres tłumaczeń
                </LinkText>
                <LinkText
                  active={activeNav === 'contact'}
                  onClick={this.closeHamburger}
                  className="navbar-item has-text-white-ter"
                  to="#contact"
                >
                  Kontakt
                </LinkText>
              </div>
            </div>
          </div>
        </Nav>
        <Overlay onClick={this.closeHamburger} active={this.state.active} />
      </>
    );
  }
};

export default Navbar;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../img/logo-min.png';

const FooterContent = styled.div`
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const FooterImgWrap = styled.div`
  margin-right: 1rem;
  background-color: #fff;
  padding: 0.25rem;
  border-radius: 50%;
`;

const FooterWrap = styled.footer`
  &&& {
    padding-bottom: 3rem;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <FooterWrap className="footer has-background-black has-text-white-ter">
      <FooterContent className="content has-text-centered">
        <FooterImgWrap>
          <img src={logo} alt="Avus" style={{ width: '22px' }} />
        </FooterImgWrap>
        Avus Artur Ożga
      </FooterContent>
      <div className="content has-text-centered">
        &copy;{` Copyright ${year}`}
      </div>
    </FooterWrap>
  );
};

export default Footer;
